body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

a {
  text-decoration: none;
  color: #007BFF;
}

.container {
  text-align: center;
  flex: 1;
}

footer {
  text-align: center;
  padding: 10px;
  background-color: #f1f1f1;
}

@keyframes heartbeat {

  0%,
  50%,
  100% {
    transform: scale(1);
  }

  25%,
  75% {
    transform: scale(1.2);
  }
}

footer span {
  display: inline-block;
  animation: heartbeat 2s infinite;
}

footer a,
header a {
  color: inherit;
  text-decoration: none;
}

footer a:hover,
header a:hover {
  text-decoration: none;
}

header {
  text-align: center;
  background-color: #f1f1f1;
}

nav ul {
  display: inline-block;
  list-style-type: none;
}

nav ul li {
  display: inline;
  margin-right: 10px;
}

nav ul li a {
  text-decoration: none;
}

nav ul li a:hover {
  text-decoration: none;
}

.post-content {
  width: 60%;
  margin: 0 auto;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

li {
  text-decoration: none;
}

.post-content ul {
  list-style-type: none;
}

div .highlight {
  background-color: #f6f8fa;
  padding: 2px;
}

img {
  width: 100%;
  height: 100%;
}
